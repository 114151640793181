import PropTypes from 'prop-types';
import React from 'react';
import Button from '@shoreag/base/Button';
import Box from '@shoreag/base/Box';

const AddButton = ({ sx, label, labelSx, wrapperSx, ...rest }) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, ...wrapperSx }}
  >
    {label && (
      <Box
        sx={{
          alignItems: 'center',
          bottom: 0,
          color: 'gray5',
          display: 'flex',
          fontSize: 2,
          fontWeight: 'bold',
          pr: 2,
          right: '100%',
          top: 0,
          whiteSpace: 'nowrap',
          ...labelSx,
        }}
      >
        {label}
      </Box>
    )}
    <Button
      simple
      sx={{
        '&:hover': { bg: 'primaryLight' },
        alignItems: 'center',
        bg: 'primary',
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        fontSize: 6,
        height: '3rem',
        justifyContent: 'center',
        position: 'relative',
        textDecoration: 'none',
        width: '3rem',
        ...sx,
      }}
      {...rest}
    >
      +
    </Button>
  </Box>
);

AddButton.propTypes = {
  label: PropTypes.string,
  labelSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

AddButton.defaultProps = {
  label: '',
  labelSx: {},
  sx: {},
  wrapperSx: {},
};

export default AddButton;
